import React from 'react'
//import {useStaticQuery graphql } from 'gatsby'
import { Box, Text, Card, Flex } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import { FaKaggle, FaGithub } from 'react-icons/fa'
/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  /*
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  */
  return (
    <>
      <Seo title='Hakkımda' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Hakkımda'
            subheader='Eğer bir işi seviyorsan, başarı sadece bir kelimeden ibarettir.'
          />
          {
            /*
            <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
            */
          }

          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='Hikayem'>
                <Text variant='p'>
                  Ben Ömer Harun Çetin. 23 yaşındayım, yaklaşık 12 yıl önce başladığım yazılım ve kodlama sevgim şu anda artarak devam etmekte.
                </Text>
                <Text variant='p'>
                  Yaklaşık 11 yaşımdayken sadece html ile statik bir sayfa oluşturarak başladığım bu yolda 13 yaşımda vb.net ve c# kullanarak
                  kendim için işimi kolaylaştıracak masaüstü programları geliştirdim.
                </Text>
                <Text variant='p'>
                  Ardından sağlık meslek lisesinde Anestezi Teknisyenliği bölümünü
                  okudum bu 4 yılda yazılıma ara verdim/bıraktım diyebiliriz.
                </Text>
                <Text variant='p'>
                  Sonrasında ise İzmir Bakırçay Üniversitesi Bilgisayar Mühendisliği bölümünü
                  kazanmamla beraber yazılıma devam etmeye başladım.Şu an 4. sınıftayım. Yaklaşık 2.5 yıl Full Stack Web Developer olarak çalıştım. Şu anda da Fullstack Blockchain Developer olarak çalışmaktayım.
                  Gelecekte de <strong>Blockchain</strong> alanında ilerlemek istiyorum.
                </Text>

                <Text variant='p'>
                  <Card variant='paper'>
                    <strong>Deneyimlerim</strong>
                    <ul>
                      <li style={{ cursor: 'pointer' }} onClick={() => window.open('https://codartbilisim.com.tr', '_blank')}>
                        Codart Bilişim ve Danışmanlık şirketinde Software Developer
                      </li>
                      <li style={{ cursor: 'pointer' }} onClick={() => window.open('https://kalybeai.com', '_blank')}>
                        Kalybeai şirketinde Web Developer and Data Scientist Candidate
                      </li>
                      <li style={{ cursor: 'pointer' }} onClick={() => window.open('https://amatis.nl', '_blank')}>
                        Amatis şirketinde Fullstack Web Developer
                      </li>
                      <li style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.kaira.network', '_blank')}>
                        Kaira Network şirketinde Fullstack Blockchain Developer
                      </li>
                    </ul>
                  </Card>
                </Text>
                <Divider />
                <Text variant='p'>
                  <Card variant='paper'>
                    <strong>Çalışmalarım</strong>
                    <ul>
                      <li style={{ cursor: 'pointer' }} onClick={() => window.open("https://github.com/omerharuncetin", "_blank")}>
                        <FaGithub /> Github
                      </li>
                      <li style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.kaggle.com/omerharuncetin", '_blank')}>
                        < FaKaggle /> Kaggle
                      </li>
                    </ul>
                  </Card>
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Web Geliştirme'>
                <Card variant='paper'>
                  Web sayfaları tasarlayabilir, Web Apiler yazabilir ve web sunucularını yönetebilirim.
                  Web alanında gelişen teknolojilere ayak uydurmak için ben de kendimi sürekli geliştiriyorum.
                </Card>
              </Section>
              <Divider />
              <Section title='Blokzincir'>
                <Card variant='paper'>
                  Akıllı kontratlar geliştirebilir. Bu akıllı kontratları web sayfaları ile etkileşime geçirebilirim. Baştan sonra bir blokzincir projesi oluşturabilirim.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </>
  )
}
/*
const aboutQuery = graphql`
  query AboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`

 */
